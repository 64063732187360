.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #6EACDA;

    .header-logo {
        display: flex;

        .image {
            width: 200px;
        }
    }

    .header-menu {
        display: flex;
    }

    .ui.secondary.pointing.menu .item {
        color: #fefefa;
        padding: 20px;
        font-size: 1.2rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    /* Media Query for Mobile Devices (max-width: 480px) */
    @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;

        .header-logo {
            .image {
                width: 150px; /* Adjust logo size for mobile */
            }
        }

        .header-menu {
            flex-direction: column;
            align-items: center;
            width: 100%; /* Make menu full-width */
        }

        .ui.secondary.pointing.menu .item {
            padding: 10px;
            font-size: 1rem; /* Smaller font size for mobile */
        }
    }

    /* Media Query for Tablets and Small Desktops (min-width: 481px and max-width: 1024px) */
    @media (min-width: 481px) and (max-width: 1024px) {
        .header-logo {
            .image {
                width: 180px; /* Slightly reduce logo size for tablets */
            }
        }

        .header-menu {
            .ui.secondary.pointing.menu .item {
                padding: 15px;
                font-size: 1.1rem; /* Adjust font size for tablets */
            }
        }
    }

    /* Media Query for Large Desktops (min-width: 1025px) */
    @media (min-width: 1025px) {
        .header-logo {
            .image {
                width: 200px; /* Original size for large desktops */
            }
        }

        .header-menu {
            .ui.secondary.pointing.menu .item {
                padding: 20px;
                font-size: 1.2rem; /* Original font size for large desktops */
            }
        }
    }
}
