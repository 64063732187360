.App {
  text-align: center;
  padding:30px;
  background-color: #6EACDA;
  justify-content: center;
  align-items: center;
  overflow-y: visible;
  .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
