.about-container {
    margin-top: 20px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .who-we-are {
        display: flex;
        .side-text {
            flex: 1;
            width: 50%;
            padding: 30px;
            color: #021526;
            .title {
                font-size: 3rem;
                color: #FFF;
            }
            .description {
                font-size: 1.5rem;
            }

        }
        .side-image {
            display: flex;
            width: 60%;
            margin-right: 20px;
            padding: 30px;
            .image {
                max-width: 100%;
                height: auto;
            }
        }
    }
    @media (max-width: 480px) {
        width: 100%;
        .who-we-are {
            flex-direction: column;
            .side-image{
                width: 100%;                
            }
            .side-text {
                width: 100%;
            }
        }
    }
}

