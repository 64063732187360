.service-container {
    width: 95%;
    margin-top: 20px;
    .service-title {
        display: flex;
        font-size: 4rem;
        color: #FFF;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/its.jpg');
        padding: 20px;
        word-spacing: 10px;
        font-weight:bolder;        
    }
    .card-container {
        display: flex;
        flex-wrap: wrap;
    }    
    .card-style {        
        flex: 1 1 ;
        padding: 20px;
        width: 30%;
        .card-look {
            background-color: transparent;
            border-bottom: 10px solid #03346E;
            border-right: 10px solid #03346E;
            border-radius: 50px;
            box-shadow: none;
            border-left: 1px solid #FFF;
            border-top: 1px solid #FFF;
        }
        .card-look:hover {
            background-color: transparent;
        }
        .ui.card>.content>.header {
            color:#FFF;
            font-size: 2rem;
            line-height: 30px;
            font-family: cursive;
            padding: 10px;
        }
        .ui.card>.content>.description {
            font-size: 1.5rem;
            line-height: 25px;
            color: #03346E;
            font-family: cursive;
        }
        .ui.card>.content>.meta {
            color: #021526;
            font-weight: bold;
            padding: 10px;
        }
    }
    @media (max-width: 480px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .card-style {
            width: 100%;
        }
    }
}