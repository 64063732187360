.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    background-color: #021526;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    word-spacing: 4px;
    margin-bottom: 200px;
    width: 95%;
    margin-top: 25px;
    .form-container {
        margin-top: 30px;
    }
    .controls {        
        width: 60%;     
        padding: 10px;
    }
    .powered-section {
        margin-top: 50px;
    }

}