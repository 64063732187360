.home-container {
    margin-top: 20px;
    background-color: #FFF;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .welcome-section {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/Home.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        color: #FFF;
        line-height: 20px;
        word-spacing: 1px;
        padding: 10px;
        width: 100%;
        .title {
            font-size: 3.5rem;
            filter: brightness(100%);
            margin-left:100px;
            margin-right: 100px;
            margin-top: 50px;
            margin-bottom: 50px;
        }
        .description {
            font-size: 1.8rem;
            word-spacing: 1px;
            margin-top: 30px;
        }
        .connect {
            margin-top: 7%;
            .btn {
                height: 60px;
                width: 200px;
                font-size: 1.5rem;
                background-color: #03346E;
                color: #FFF
            }
        }
    }
    .features {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        .description {
            font-size: 1.5rem;
            font-weight: bold;
            margin-top: 30px;
            font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
        }
        .ques {
            display: flex;
            .ques-title {
                font-size: 3.5rem;
                filter: brightness(100%);
                margin-left:100px;
                margin-right: 100px;
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }
        .services {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 95%;            
            .item {
                padding: 40px;
                width: 30%;
                height: 550px;
                background-color: #6EACDA;
                margin-top: 50px;
                .icon {
                    font-size: 3rem;
                    color: #FFF;
                }
                .description {
                    color: #FFF
                }
                .services-list {
                    list-style-type:decimal;
                    font-size: 1.5rem;
                    color:#FFF;
                    line-height: 40px;
                }

            }            
        }

    }
    .clients {
        margin-top:50px;
        margin-bottom: 30px;
        .images {
            width: 300px;
            padding: 30px;
        }
    }

    @media (max-width: 480px){
        width: 100%;
        .welcome-section {
            height: fit-content;
            .title {
                margin:auto;
            }
        }
        .features {            
            .services {
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: #FFF;
                .item {
                    width: 80%;
                }
            }
            .subheading {
                font-size: 1.71428571rem;
                padding: 10px;
                width: 50%;
            }
            .ques {
                .ques-title {
                    font-size: 3rem;
                }
            }
        }
        .clients {
            margin-top:50px;
            margin-bottom: 30px;
            .images {
                width: 300px;
                padding: 30px;
                width: 80%;
            }
        }
    }
}